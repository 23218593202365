/* global config, Promise, angular */

'use strict';

angular.module('managerApp').factory('$_webapps', webappsService);
function webappsService(http) {
    let url = config.apiURL + 'webapps/', errors = [422, 500];
    function errorHandler(err) {
        if (err && err.data && err.data.message) {
            return Promise.reject(err.data.message);
        }
        return Promise.reject(err);
    }
    return {
        get: (by = {}, page = 1, pageSize) => {
            let _url = url, params = [];
            if (by) {
                if (by.type) {
                    params.push('filter[type]=' + by.type);
                }
                if (by.partners) {
                    _url += 'partners/';
                }
                if (by.PartnerId) {
                    _url += `id/${by.PartnerId}`;
                }
                if (by.id) {
                    _url += by.id;
                } else { // options only for many results
                    for (let i in by) {
                        if (['id', 'type'].indexOf(i) < 0) {
                            params.push('filter[' + i + ']=' + by[i]);
                        }
                    }
                    if (page > 1) {
                        params.push('page=' + parseInt(page));
                    }
                    if (pageSize) {
                        params.push('pageSize=' + parseInt(pageSize));
                    }
                }
            }
            if (params.length > 0) {
                _url += '?' + params.join('&');
            }
            return new Promise((resolve, reject) => {
                http.get(_url).then((res) => {
                    if (res.data.count && res.data.rows) {
                        resolve({
                            count: res.data.count,
                            items: res.data.rows
                        });
                    }
                    resolve(res.data);
                }).catch(errorHandler);
            });
        },
        getSimple: () => {
            let _url = url, params = [];
            params.push('filter[namesIdsOnly]=1');
            if (params.length > 0) {
                _url += '?' + params.join('&');
            }
            return new Promise((resolve, reject) => {
                http.get(_url).then((res) => {
                    resolve(res.data);
                }).catch(errorHandler);
            });
        },
        add: (item, partners = false) => {
            let type = partners ? 'partners' : '';
            return http.post(url + type, item).then((res) => {
                return Promise.resolve(res.data);
            }).catch(errorHandler);
        },
        update: (item, itemId, partners = false) => {
            let type = partners ? 'partners/' : '';
            return http.put(url + type + (item._id ? item._id : itemId), item).then((res) => {
                return Promise.resolve(res);
            }).catch(errorHandler);
        },
        createPartnerProcedurePrice(partnerId, price) {
            return http.post(url + 'partners/' + partnerId + '/prices', price).then((res) => {
                return Promise.resolve(res.data);
            }).catch(errorHandler);
        },
        updatePartnerProcedurePrice(partnerId, price) {
            return http.put(url + 'partners/' + partnerId + '/prices/' + price._id, price).then((res) => {
                return Promise.resolve(res.data);
            }).catch(errorHandler);
        },
        generateToken: (itemId) => {
            let _url = url + itemId + '/token';
            return http.get(_url).then((res) => {
                return Promise.resolve(res.data);
            }).catch(errorHandler);
        },
        updateActivationCode: (itemId) => {
            return http.put(url + itemId + '/code').then((res) => {
                return Promise.resolve(res.data);
            }).catch(errorHandler);
        },
        activateWebApp: (activateCode) => {
            return http.put(url + 'activate', {
                code: activateCode
            }).then((res) => {
                return Promise.resolve(res.data);
            }).catch(errorHandler);
        }
    };
}
